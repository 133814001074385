import cn from 'classnames';
import { BaseProps } from 'types';

type ResponsiveType =
  | 'mobile'
  | 'small-tablet'
  | 'tablet'
  | 'small-desktop'
  | 'desktop'
  | 'wide'
  | 'extra-wide'
  | 'extra-extra-wide';

export interface ResponsiveProps extends BaseProps {
  until?: ResponsiveType;
  from?: ResponsiveType;
}

const Responsive: React.FC<ResponsiveProps> = ({
  className,
  until,
  from = 'small-desktop',
  children,
  ...props
}) => (
  <div
    className={cn(className, { [`until--${until}`]: until, [`from--${from}`]: !until && from })}
    {...props}
  >
    {children}
  </div>
);

export default Responsive;
